import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

// import Users from "./user/pages/Users";
// import NewBin from "./places/pages/NewBin";
// import UserBins from "./places/pages/UserBins";
// import UpdateBin from "./places/pages/UpdateBin";
// import Auth from "./user/pages/Auth";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

const About = React.lazy(() => import("./site/pages/About"));
const Users = React.lazy(() => import("./user/pages/Users"));
const Search = React.lazy(() => import("./places/pages/Search"));
const GetItem = React.lazy(() => import("./places/pages/GetItem"));
const NewBin = React.lazy(() => import("./places/pages/NewBin"));
const UserBins = React.lazy(() => import("./places/pages/UserBins"));
const UpdateBin = React.lazy(() => import("./places/pages/UpdateBin"));
const NewItem = React.lazy(() => import("./places/pages/NewItem"));
const UserItems = React.lazy(() => import("./places/pages/UserItems"));
const BinItems = React.lazy(() => import("./places/pages/BinItems"));
const GetBin = React.lazy(() => import("./places/pages/GetBin"));
const UserProfile = React.lazy(() => import("./user/pages/UserProfile"));
const UpdateItem = React.lazy(() => import("./places/pages/UpdateItem"));
const UpdateUser = React.lazy(() => import("./user/pages/UpdateUser"));
const SharedBins = React.lazy(() => import("./places/pages/SharedBins"));
const Welcome = React.lazy(() => import("./site/pages/Welcome"));
const Help = React.lazy(() => import("./site/pages/Help"));
const Auth = React.lazy(() => import("./user/pages/Auth"));

const App = () => {
  const { token, login, logout, userId, email } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <UserBins />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/allUsers">
          <Users />
        </Route>
        <Route path="/items/search" exact>
          <Search />
        </Route>
        <Route path="/item/:itemId" exact>
          <GetItem />
        </Route>
        <Route path="/:userId/bins/:type" exact>
          <UserBins />
        </Route>
        <Route path="/bins/shared" exact>
          <SharedBins />
        </Route>
        <Route path="/bins/new" exact>
          <NewBin />
        </Route>
        <Route path="/bins/:binId" exact>
          <UpdateBin />
        </Route>
        <Route path="/bin/:binId" exact>
          <GetBin />
        </Route>
        <Route path="/items/new">
          <NewItem />
        </Route>
        <Route path="/items/bin/:binId/:type" exact>
          <BinItems />
        </Route>
        <Route path="/users/:userId" exact>
          <UserProfile />
        </Route>
        <Route path="/users/update/:userId" exact>
          <UpdateUser />
        </Route>
        <Route path="/:userId/items/:type">
          <UserItems />
        </Route>
        <Route path="/items/:itemId">
          <UpdateItem />
        </Route>
        <Route path="/help" exact>
          <Help />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Welcome />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/auth">
          <Auth />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token, // !! converts a string to true if it's truthy or false if it's null or undefined
        token: token,
        userId: userId,
        email: email,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <MainNavigation />
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
