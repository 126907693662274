import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./NavDropDown.css";

function NavDropDown(props) {
  const auth = useContext(AuthContext);
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  // console.log("dropdown click: ", click);

  return (
    <>
      <section className="drop-section">
        <ul
          onClick={handleClick}
          className={click ? "nav-drop-menu" : "nav-dropdown-menu"}
        >
          <li>
            <Tippy
              content={
                <span>
                  The detailed list shows all your bins and their details,
                  including edit bin and view bin's contents.
                </span>
              }
              delay={[1000, 100]}
              theme="light"
            >
              <Link
                className="nav-menu-items"
                to={`/${auth.userId}/bins/long`}
                onClick={props.toggleDrawerClose}
              >
                My Bins (detailed)
              </Link>
            </Tippy>
          </li>
          <li>
            <Tippy
              content={
                <span>
                  The brief list shows less info about your bins. Clicking a bin
                  shows its contents.
                </span>
              }
              delay={[1000, 100]}
              theme="light"
            >
              <Link
                className="nav-menu-items"
                to={`/${auth.userId}/bins/short`}
                onClick={props.toggleDrawerClose}
              >
                My Bins (brief)
              </Link>
            </Tippy>
          </li>
          <li>
            <Tippy
              content={
                <span>
                  This shows a table list of all your bins. Clicking a bin
                  shows its contents.
                </span>
              }
              delay={[1000, 100]}
              theme="light"
            >
              <Link
                className="nav-menu-items"
                to={`/${auth.userId}/bins/list`}
                onClick={props.toggleDrawerClose}
              >
                My Bins (list)
              </Link>
            </Tippy>
          </li>
          <li>
            <Tippy
              content={
                <span>
                  Shared Bins are bins that have been shared with you by
                  Binventory community members.
                </span>
              }
              delay={[1000, 100]}
              theme="light"
            >
              <Link
                className="nav-menu-items"
                to={"/bins/shared"}
                onClick={props.toggleDrawerClose}
              >
                Shared With Me
              </Link>
            </Tippy>
          </li>
          <li>
            <Link
              className="nav-menu-items"
              to={"/bins/new"}
              onClick={props.toggleDrawerClose}
            >
              Add Bin
            </Link>
          </li>
          <li>
            <Link
              className="nav-menu-items"
              to={`/${auth.userId}/items/long`}
              onClick={props.toggleDrawerClose}
            >
              My Items (detailed)
            </Link>
          </li>
          <li>
            <Link
              className="nav-menu-items"
              to={`/${auth.userId}/items/list`}
              onClick={props.toggleDrawerClose}
            >
              My Items (list)
            </Link>
          </li>
          <li>
            <Link
              className="nav-menu-items"
              to="/items/new"
              onClick={props.toggleDrawerClose}
            >
              Add Item
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
}

export default NavDropDown;
