import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { Link } from "react-router-dom";
import "./NavDropDown.css";

function NavDropDownProfile(props) {
  const auth = useContext(AuthContext);
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  // console.log("dropdownProfile click: ", click);

  return (
    <>
      <section className="drop-section">
        <ul
          onClick={handleClick}
          className={click ? "nav-drop-menu" : "nav-dropdown-profile-menu"}
        >
          <li>
            <Link
              className="nav-menu-items"
              to={`/users/${auth.userId}`}
              onClick={props.toggleDrawerClose}
            >
              My Profile
            </Link>
          </li>
          <li>
            <Link
              className="nav-menu-items"
              to={"/help"}
              onClick={props.toggleDrawerClose}
            >
              Help
            </Link>
          </li>
          <li>
            <Link to="" className="nav-menu-items" onClick={auth.logout}>
              Log Out
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
}

export default NavDropDownProfile;
